@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');
@import "~bootstrap/dist/css/bootstrap.css";

/* Your additional styles go here */

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #fcfcfc;
}

.container {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.col {
  padding: 0px;
}

.col-md-6 {
  padding: 0px;
}

.col-sm-12 {
  padding: 0px;
}

.navbar {
  height: 10vh;
  background-color: #010101;
  position: relative;
  color: white;
  border-bottom: 4px solid #DD4B12;
}

.logo-img {
  width: 200px;
  height: 100%;
  object-fit: cover;
}

.logo {
  display: flex;
}


.menu-icon {
  display: none;
}


.nav-elements {
  z-index: 999;
}

.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  margin: 0px;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 40px;
}

.nav-elements ul a {
  font-size: 16px;
  font-weight: 400;
  color: white;
  text-decoration: none;
}

.nav-elements ul a.active {
  color: white;
  font-weight: 500;
  position: relative;
}

.nav-elements ul a.active::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: white;
}

.logo-img {
  cursor: pointer;
}

@media (max-width: 768px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }
}

@media (max-width: 920px) {
  .menu-icon {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
  }

  .nav-elements {
    position: absolute;
    right: 0;
    top: 78px;
    background-color: #DD4B12;
    width: 0px;
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in;
    overflow: hidden;
  }

  .nav-elements.active {
    width: 270px;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
  }
}

/* Home Page */

.home-img {
  width: 100%;
  height: 500px;
  background-repeat: no-repeat;
  object-fit: cover;
  max-height: 600px;
  min-height: 60vh;
}

.home-title {
  font-size: 80px;
  font-weight: 800;
}

.orange-btn {
  color: white;
  font-weight: 800;
  border: 3px solid #DD4B12;
  background-color: #DD4B12;
  border-radius: 0px;
  font-size: 25px;
  white-space: nowrap;
}

.orange-btn:hover {
  border: 5px solid #DD4B12;
  background-color: rgba(221, 75, 18, .5);
}

.home-subtitle {
  font-size: 30px;
}

.test {
  position: relative;
}

.center {
  position: absolute;
  top: 68%;
  left: 85%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  margin: 0px;
}

.kitchen-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.title {
  font-size: 40px;
}

.kitchen-img {
  position: relative;
}

.overlay-text {
  position: absolute;
  top: 70%;
  left: 0;
  color: white;
  padding: 20px;
}

.extension-example {
  position: relative;
  height: 100%;
}

/* Footer */

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.footer {
  background-color: #010101;
  color: white;
  margin-top: auto;
}

.social-icons a {
  color: white;
  font-size: 50px;
}

.footer-img {
  height: 100px;
  width: 100px;
  object-fit: contain;
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-icons {
  height: 20px;
  width: 20px;
  color: #DD4B12
}

.line {
  border-bottom: 5px solid #DD4B12;
}

.phone {
  display: flex;
}

.email {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.social-icons {
  display: flex;
  height: 80px;
  width: 80px;
  font-size: 100px;
  cursor: pointer;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.phone-number {
  margin-left: 5px;
  margin-bottom: 0px;
}

.email-address {
  margin-left: 5px;
  margin-bottom: 0px;
  cursor: pointer;
  color: white;
  text-decoration: none;
}

.email-address:hover {
  text-decoration: underline;
}

.div {
  padding: 10px;
  max-width: 300px;
}

.design-img {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

.design-imgg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.extra-padding {
  padding: 0px 130px;
}


.bullet-points ul {
  padding: 0px 15px;
}
.contact-btn {
  border: 3px solid #DD4B12;
  border-radius: 0px;
  text-decoration: none;
  color: black;
  padding: 10px;
}

.contact-build-btn {
  border: 3px solid #DD4B12;
  border-radius: 0px;
  width: 100%;
}

.contact-btn:hover {
  background-color: #DD4B12;
  color: white;
}

.contact-build-btn:hover {
  background-color: #DD4B12;
  color: white;
}
.gallary-img {
  width: 400px;
  height: 400px;
  object-fit: cover;
}

.gallary-title {
  margin-left: 30px;
}

.gallery-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px; /* Adjust margin as needed */
}

.site-team-container {
  display: flex;
  flex-wrap: wrap;
}

.gallery-item {
  flex: 0 0 calc(33.33% - 20px); /* Three items in a row on larger screens */
  margin: 10px; /* Adjust margin as needed */
  box-sizing: border-box;
}

.gallery-item img {
  width: 100%;
  min-height: 300px;
  max-height: 300px;
}


.gallary-name-tag {
  width: 100%;
  height: 50px;
  background-color: black;
  color: white;
  font-size: 30px;
}

.gallary-padding {
  padding: 0px 40px;
}

@media (max-width: 1199px) {
  /* For screens between 768px and 1199px (medium devices) */
  .gallery-item {
    flex: 0 0 calc(50% - 20px); /* Two items in a row for medium devices */
  }
}

@media (max-width: 767px) {
  /* For screens smaller than 768px (e.g., mobile devices) */
  .gallery-item {
    flex: 0 0 calc(100% - 20px); /* One item in a row on smaller screens */
  }
}


@media (max-width: 1052px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }
  .logo-img {
    width: 200px;
  }
}
@media (max-width: 830px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 20px;
  }
  .logo-img {
    width: 200px;
  }
}

.testimonial-item p {
  width: 300px;
  height: auto;
  display: block;
}

/* Contact */


.contact {
  text-align: center;
  background-color: #FAFAFA;
}

.contact form {
  background-color: white;
  border-radius: 5px;
  height: 100%;
  width: 400px;
  padding: 20px;
  
}

.contact form input {
  width: 100%;
  border: 1px solid #CDD5DA;
  padding: 10px;
}

.contact form textarea {
  padding: 10px;
}

.contact form button {
  background-color: #2449A9;
  width: 100%;
  color: white;
}

.contact form textarea {
  border: 1px solid #CDD5DA;
  width: 100%;
}

.contact-form-border {
  border-bottom: 4px solid #809EDE;
  width: 25%;
  margin: 20px 0px;
}


.form-content {
  text-align: left;
  padding: 0px 30px;
}


.btn-form {
  border: 2px solid #DD4B12;
  border-radius: 0px;
}

.btn-form:hover {
  background-color: #DD4B12;
  color: white;

}

.white-btn {
  border: 4px solid white;
  border-radius: 0px;
  height: 50%;
  color: white;
}

.white-btn:hover {
  background-color: rgba(255, 255, 255, .5);
  border: 4px solid rgb(255, 255, 255);
  color: white;
}

/* blogs */

.blog-card {
  height: 300px;
  width: 300px;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
}


.blog-img {
  height: 100%;
  width: 100%;
}

.blog-btn {
 border: 3px solid #DD4B12;
 border-radius: 0px;
 display: flex;
 justify-content: center;
 align-items: center;
 text-decoration: none;
 color: black;


}

@media (min-width: 692px) {
  .blog-card {
    width: calc(33.33% - 50px); 
  }
}
@media (max-width: 1000px) {
  .blog-card {
    width: calc(33.33% - 30px); 
  }
}
@media (max-width: 700px) {
  .blog-card {
    width: calc(100% - 30px); 
  }
}
@media (max-width: 700px) {
  .blog-card {
    min-width: 200px;
    min-height: 400px;
  }
}



@media (max-width: 800px) {
  .div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .mobile-footer {
    display: flex;
    flex-direction: column;
  }

  .mobile-text {
    text-align: center;
  }

}

.flippable-card-container {
  display: flex;  /* Use flexbox to make the cards sit next to each other */
  height: auto;
  width: 100%;  /* Use 100% width to ensure it takes the full width of the container */
  perspective: 1000px;
  justify-content: space-between;  /* Adjust spacing between cards */
  flex-wrap: wrap;
  margin-bottom: 20px;
}


.card-img {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  object-fit: cover;
  object-position: top;
}

.details {
  position: absolute;
  bottom: 30px;
  left: 20px;
  color: white;
  font-weight: 800;
}

.position {
  font-weight: 400;
}

.border-position {
  border-bottom: 5px solid white;
  width: 50%;
  
}

.person-stats {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 20px;
  padding: 0px 20px;

}

.stat {
  font-size: 10px;
  display: flex;
  margin: 0px;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  color: white;
}

.stat dd {
  width: 100%;
}

.stat dt {
  width: 45%;
  text-align: right;
  padding: 0 1rem 0 0;
  text-transform: uppercase;
}

.stat-block {
  background: #DD4B12;
  text-align: right;
  padding: 4px 5px;
  text-transform: uppercase;
  display: table;
  transition: width .25s ease;
  width: 0;
}


.card {
  flex: 0 0 calc(33.33% - 20px); /* Three items in a row on larger screens */
  margin-bottom: 20px; /* Adjust margin as needed */
  box-sizing: border-box;
  
}

@media (max-width: 1199px) {
  .card {
    flex: 0 0 calc(50% - 20px); /* Two cards in a row for medium devices */
  }
}

@media (max-width: 767px) {
  .card {
    flex: 0 0 calc(100% - 20px); /* One card in a row on smaller screens */
  }

  .center {
    top: 65%;
    left: 70%;
  }

  .sm-p-build {
    margin-top: 50px;
  }

}


@media (max-width: 500px) {
  .contact form {
    width: 300px;
  }

  .navbar-m {
    margin: 0px !important;
  }
}


@media (max-width: 800px) {

  .img-contain {
    object-fit: cover !important;
    height: 100%;
  }

  .mobile-design {
    display: flex;
    flex-direction: column;
  }

  .extra-padding {
    padding: 0px 10px;
  }

  .col-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .design-title {
    text-align: center;
  }

  .content-center {
    justify-content: center !important;
  }

}

.contact-banner {
  background-color: #DD4B12;
  color: white;
}

.banner-btn {
  border: 3px solid white;
  color: white;
  border-radius: 0px;
  margin: 30px 0px;
  font-weight: 800;
  font-size: 25px;
}

.banner-btn:hover {
  border: 3px solid white;
  background-color: rgba(255, 255, 255, .5);
  color: white;
}

.post-card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: none;
  transition: box-shadow 0.3s ease-in-out;

}

.post-card:hover {
  box-shadow: 0 8px 16px rgba(221, 75, 18, .2);
}

.card-body {
  overflow-y: scroll;
}

.img-contain {
  object-fit: contain !important;
}

.gallery-img {
  height: 50vh;
  width: 100%;
  object-fit: cover;
  background: rgba(19,15,64,0.8);
  /* opacity: .5; */
}


.overlay-gallery {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.gallery-item {
  position: relative;
  margin: 10px;
}

.gallery-item-text {
  position: absolute;
  bottom: 150px;
  font-size: 40px;
  font-weight: 500;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  color: white;
  padding: 5px 10px;
}
.gallery-item-text-2 {
  position: absolute;
  white-space: nowrap;
  bottom: 80px;
  font-size: 30px;
  font-weight: 400;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  color: white;
  padding: 5px 10px;
}



.container.gallery-container {
  background-color: #fff;
  color: #35373a;
  min-height: 100vh;
  padding: 30px 50px;
}

.gallery-container h1 {
  text-align: center;
  margin-top: 50px;
  font-family: 'Droid Sans', sans-serif;
  font-weight: bold;
}

.gallery-container p.page-description {
  text-align: center;
  margin: 25px auto;
  font-size: 18px;
  color: #999;
}

.tz-gallery {
  padding: 40px;
}

/* Override bootstrap column paddings */
.tz-gallery .row > div {
  padding: 9px;
}
.tz-gallery .row > div:hover {
  transform: scale(1.03);
}

.tz-gallery .lightbox img {
  width: 100%;
  border-radius: 0;
  position: relative;
  height: 100%;
  object-fit: cover;
  min-height: 300px; /* Set a fixed height for all images */
  max-height: 300px;
}


.baguetteBox-button {
  background-color: transparent !important;
}

@media(max-width: 768px) {
  body {
      padding: 0;
  }
}