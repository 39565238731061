.card {
    height: 500px;
    width: 100%;
    position: relative;
    transform-style: preserve-3d;
    transition: transform 1000ms;
    user-select: none;
    cursor: pointer;
    border-radius: 0px;
}


.card-front, .card-back {
    width: 100%;
    height: 100%;
    font-size: 20px;
    color: black;
    position: absolute;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
}


.card-back {
    transform: rotateY(180deg);
}

.flip-exit {
    transform: rotateY(0deg);
}

.flip-exit-active {
    transform: rotateY(180deg);
    transition: transform 300ms;
    transition-timing-function: cubic-bezier(.57, .21, .69, 1.25);

}

.flip-exit-done {
    transform: rotateY(180deg);
}

.flip-enter {
    transform: rotateY(180deg);
}

.flip-enter-active {
    transform: rotateY(0deg);
    transition: transform 300ms;
    transition-timing-function: cubic-bezier(.57, .21, .69, 1.25);
}

.flip-enter-done {
    transform: rotateY(0deg);
}


